<template>
  <div class="gradient-stripe"></div>
</template>

<style scoped>
.gradient-stripe {
  width: 100%;
  height: 24px;
  background: linear-gradient(
    90deg,
    #FFEE8F 10.28%,
    #E3FBCF 20.63%,
    #BBF0FA 42.36%,
    #ABD9FF 56.45%,
    #5DABF3 70.52%,
    #448FD6 84.88%,
    #3262BA 100%
  );
}
</style>
