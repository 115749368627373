<template>
  <router-view> </router-view>
</template>

<script>
import '@icon-park/vue-next/styles/index.css'
export default {}
</script>

<style>
#app {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-left: 0px;
}
</style>
